import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUploaderDropzone from "./FileUploaderDropzone";
import primroseLogo from "../../assets/primrose-brand-logo-sm-1x.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  uploadFile,
  uploadStatus,
  downloadFile,
} from "../../Redux/Upload/upload.actions";
import { logout } from "../../Redux/Login/login.actions";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import Dropdown from "components/Dropdown";
import toast, { Toaster } from "react-hot-toast";
import "./style.css";
import { useNavigate } from "react-router-dom";

// Define clinic and source EMR data
let clinics = [
  { name: "Select Clinic", value: 1 },
  { name: "Demo Clinic", value: 83622 },
  { name: "SN Cardiology Associates LLC", value: 73622 },
  { name: "Lung Specialist Associates of Texas", value: 93622 },
  { name: "Heartful Cardiology", value: 63622 },
  { name: "Impulse Diagnostics TX Inc", value: 73623 },
  { name: "Beats Cardiology PLLC", value: 93722 },
  { name: "Elite Cardiovascular Group", value: 93822 },
  { name: "Sunnyvale Sports Medicine & Orthopedic Center", value: 93522 },
  { name: "Heart360 Specialists PC", value: 93422 },
  { name: "Covenant Womens Health PLLC", value: 93322 },
  { name: "Clinton J Baird PLC", value: 93022 },
  { name: "Judith And Uche Egbuta LLC", value: 93122 },
];

let sourceEmr = [
  { name: "ECW", value: 1 },
  { name: "Advanced MD", value: 3 },
];
function Upload() {
  const [files, setFiles] = useState([]);
  const [clinicId, setClinicId] = useState(1);
  const [success, setSuccess] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [fileName, setFileName] = useState("");
  const [emrType, setEmrType] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [stepFailed, setStepFailed] = useState(false);
  const steps = stepFailed
    ? ["Starting", "Uploading", "Extracting", "Failed"]
    : ["Starting", "Uploading", "Extracting", "Completed"];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStepFailed = (step) => {
    return step === 3;
  };
  const { loginResponse } = useSelector((state) => state.login);
  const refreshToken = localStorage.getItem("refreshToken");
  // Retrieve necessary data from Redux store
  const { fileUploadStatus, uploadResponse, file } = useSelector(
    (state) => state.upload
  );

  // Handler for closing progress dialog
  const handleCloseProgress = () => {
    setOpenProgress(false);
    setOpenSuccess(true);
    setButtonDisabled(true);
  };

  // Handler for closing success dialog
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  // Handler for getting files from dropzone component
  const getFiles = (files) => {
    setFiles(files);
  };

  // Handler for exporting file
  const handleExport = () => {
    dispatch(downloadFile(fileName));
  };
  //handler for Logout
  const handleLogout = () => {
    dispatch(logout(refreshToken));
  };
  // Handler for cancel action
  const handleCancel = () => {
    //  cancel logic
  };

  // Handler for clinic selection change
  const handleClinicChange = (event) => {
    setClinicId(event.target.value);
  };

  // Handler for uploading files
  const handleUpload = () => {
    if (files.length === 2) {
      if (clinicId !== 1) {
        const data = new FormData();
        data.append("file1", files[0]);
        data.append("file2", files[1]);
        dispatch(
          uploadFile({
            clinicId: clinicId,
            file: data,
            emrType: emrType,
          })
        );
        setOpenProgress(true);
        setActiveStep(1);
        setTimeout(() => dispatch(uploadStatus(clinicId), 10000));
      } else {
        toast.error("Please select a clinic");
      }
    } else {
      toast.error("The number of files required is 2");
    }
  };

  // Effect hook for handling file upload status
  useEffect(() => {
    if (fileUploadStatus && fileUploadStatus.data) {
      const interval = setInterval(() => {
        if (fileUploadStatus.data.Status === 2) {
          setActiveStep(2);
          dispatch(uploadStatus(clinicId));
        } else if (fileUploadStatus.data.Status === 3) {
          setActiveStep(3);
          dispatch(uploadStatus(clinicId));
        } else if (fileUploadStatus.data.Status === 4) {
          setActiveStep(4);
          clearInterval(interval);
          setSuccess(true);
          setButtonDisabled(false);
        } else {
          dispatch(uploadStatus(clinicId));
        }
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [fileUploadStatus, clinicId]);

  // Effect hook for handling upload response
  useEffect(() => {
    if (uploadResponse && uploadResponse.data) {
      if (uploadResponse.responseCode === 0) {
        let name = uploadResponse.data.resultFileName;
        setFileName(name);
      } else if (uploadResponse.responseCode === 500) {
        navigate("/");
      } else {
        setActiveStep(4);
        setStepFailed(true);
      }
    }
  }, [uploadResponse]);

  // Effect hook for handling file download
  useEffect(() => {
    if (file) {
      let blobData = file;
      let fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      download(blobData, fileName, fileType);
    }
  }, [file]);

  // Handler for selecting EMR type
  const handleEmrType = (event) => {
    setEmrType(event.target.value);
  };

  // Helper function for downloading content
  const download = (content, fileName, contentType) => {
    let a = document.createElement("a");
    let blob = new Blob([content], {
      type: contentType,
    });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };
  return (
    <div className="upload__page">
      <header className="upload__header">
        <div className="upload__header__bar">
          <a href="#0">
            <img
              src={primroseLogo}
              alt="primrose-health-logo"
              className="upload__header__logo"
            />
          </a>

          <div className="clinicName">
            <Dropdown
              className="clinics_dropdown"
              options={clinics}
              value={clinicId}
              onChange={handleClinicChange}
            />
            <div>
              <Button
                className="upload_logoutBtn"
                variant="contained"
                color="primary"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
          {/* <div className="clinicName">SN Cardiology</div> */}
        </div>
      </header>
      <main className="upload__main">
        <section className="upload__section">
          <div className="uploadCsvDialog__header">
            <h3>Internal Claims Uploader</h3>
          </div>
          <div className="toggleBtn_div">
            {/* <ToggleButtonGroup
              className="toggleBtn"
              value={emrType}
              exclusive
              onChange={handleEmrType}
              aria-label="Platform"
            >
              <ToggleButton value={1}>ECW</ToggleButton>
              <ToggleButton value={3}>
                <span className="customText">Advanced MD</span>
              </ToggleButton>
            </ToggleButtonGroup> */}
            <Dropdown
              className="clinics_dropdown"
              label="Source EMR"
              options={sourceEmr}
              value={emrType}
              onChange={handleEmrType}
            />
          </div>
          <div className="uploadCsvDialog__uploadArea">
            <FileUploaderDropzone
              status={success}
              getFiles={(e) => getFiles(e)}
            />
          </div>
          <Toaster position="top-center" reverseOrder={false} />
          <div className="uploadCsvDialog__btnWrapper">
            <Button
              className="cancelBtn"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="upload_submitBtn"
              variant="contained"
              color="primary"
              onClick={handleUpload}
            >
              Submit
            </Button>
          </div>
          {/* <Dialog
            maxWidth="xs"
            open={openProgress}
            onClose={handleCloseProgress}
            aria-labelledby="uploading-dialog"
          >
            <DialogContent>
              <DialogContentText>
                Uploading file &nbsp;{" "}
                <CircularProgress size={20} color="primary" />
              </DialogContentText>
            </DialogContent>
          </Dialog> */}

          <Dialog
            open={openProgress}
            // onClose={handleCloseProgress}
            fullWidth={true}
            maxWidth={"sm"}
            className="progressBar"
          >
            <Stepper
              className="stepper"
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label, index) => {
                const labelProps = {};
                if (stepFailed) {
                  if (isStepFailed(index)) {
                    labelProps.error = true;
                  }
                }
                return (
                  <Step key={label}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className="stepper_button_wrapper">
              <Button
                onClick={handleCloseProgress}
                variant="contained"
                color="primary"
                disabled={buttonDisabled}
              >
                OK
              </Button>
            </div>
          </Dialog>

          <Dialog
            // maxWidth="xs"
            open={openSuccess}
            onClose={handleCloseSuccess}
            aria-labelledby="upload-successful-dialog"
            className="success_dialog_container"
          >
            <DialogContent>
              <Grid container className="success_dialog">
                <Grid item xs={12}>
                  <div className="success_heading_wrap">
                    <div className="success_heading">
                      Upload Successful{" "}
                      <span className="tick_icon">
                        <CheckCircleIcon />
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="success_content">
                    Click on Export to download the results
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="success_buttons">
                    <Button
                      variant="contained"
                      color="primary"
                      className="export_btn"
                      onClick={() => handleExport()}
                    >
                      Export
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="ok_btn"
                      onClick={() => setOpenSuccess(false)}
                    >
                      OK
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </section>
      </main>
    </div>
  );
}

export default Upload;
