import React, { useEffect, useState } from "react";
import { useForm } from "Hooks/validator";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import primroseLogo from "../../assets/images/primrose-brand-logo-sm-0.5x.png";
import Input from "components/Input";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createLogin } from "Redux/Login/login.actions";
import "./style.css";

/**
 *Main function of the page page
 * @param props can be used if values needs to be passed to this component,
 * LoginData prop contains the login api response data
 * @returns {JSX.Element}
 */

const Login = () => {
  const [loginError, setloginError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const { loginResponse } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues = {
    browserVersion: "Chrome100.0.4896.127",
    device: 1,
    ipAddress: "198.32:34:23",
    clinicId: 0,
    userName: "",
    password: "",
  };
  const customErrorAttribute = {
    error: true,
  };
  const { values, setValues, useInput, isValid } = useForm(
    defaultValues,
    customErrorAttribute
  );

  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      localStorage.setItem("clinic_id", values.clinicId);
      dispatch(createLogin(values));
    }
  };
  //remove credentials
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("memoryCash");
    localStorage.removeItem("user_data");
    localStorage.removeItem("clinic_id");
    let clinic_id = JSON.parse(localStorage.getItem("clinic_id"));
    if (clinic_id) {
      setValues({
        ...values,
        ...{
          clinicId: clinic_id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // store login credentials
  useEffect(() => {
    let responseCode = loginResponse.responseCode;
    // console.log(responseCode);
    // console.log(loginResponse);
    if (loginResponse && loginResponse?.data) {
      const { data } = loginResponse;
      if (data && responseCode === 0) {
        localStorage.setItem("access_token", data.accessToken);
        localStorage.setItem(
          "isAdministratorAccess",
          data.isAdministratorAccess
        );
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("roleId", data.roles[0].roleId);
        localStorage.setItem("role", data.roles[0]);
        localStorage.setItem("page", 0);
        navigate("/upload");
      }
    } else if (responseCode === 117) {
      console.log("Invalid");
      setloginError(true);
      setErrMessage("Login failed. Please enter valid credentials");
    } else if (responseCode === 159) {
      setloginError(true);
      setErrMessage(
        "We are working to ensure your account is activated. Due to demand, this is taking longer than we anticipated. We will reach out to you as soon as possible to verify your information and activate the account. Thank you for your patience"
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse]);

  return (
    <div className="clinicLogin scrollbarY--custom">
      <header className="clinicLogin__header">
        <div className="clinicLogin__header__bar">
          <a href="#0">
            <img
              src={primroseLogo}
              alt="primrose-health-logo"
              className="clinicLogin__header__logo"
            />
          </a>
        </div>
      </header>

      <main className="clinicLogin__main">
        <section className="clinicLogin__section">
          <div>
            <h1 className="clinicLogin__grid__heading">
              Internal Claims Uploader
            </h1>
          </div>

          <div>
            <form onSubmit={handleSubmit} className="clinicLogin__form">
              <div className="clinicLogin__inputField">
                {/* <Input
                  margin="normal"
                  autoFocus
                  // type="text"
                  label="Clinic ID"
                  variant="outlined"
                  className="login_input"
                  error={loginError ? true : false}
                  {...useInput("clinicId", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid ID",
                    },
                  })}
                /> */}

                <Input
                  margin="normal"
                  type="text"
                  label="Username"
                  variant="outlined"
                  className="login_input"
                  error={loginError ? true : false}
                  {...useInput("userName", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid Username",
                    },
                  })}
                />

                <Input
                  margin="normal"
                  required
                  type="password"
                  label="Password"
                  variant="outlined"
                  className="login_input"
                  error={loginError}
                  autoComplete="current-password"
                  helperText={errMessage}
                  {...useInput("password", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid Password",
                    },
                  })}
                />

                <div className="btnWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // btname="Login"
                    className="clinicLogin__submitBtn"
                    // onClick={redirect}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </form>

            <div className="footer_container"></div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Login;
